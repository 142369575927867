import React from "react";

const UnderConstruction = () => {
  return (
    <div className="bg-gray-800 h-screen flex flex-col items-center justify-center">
      <h1 className="text-white text-3xl font-bold mb-6 animate-pulse">
        In Arbeit...
      </h1>
      <div className="flex w-1/2 h-4 bg-gray-500 rounded-full overflow-hidden">
        <div className="bg-blue-500 h-full w-1/3"></div>
        <div className="bg-blue-500 h-full w-1/3 animation-delay-100"></div>
        <div className="bg-blue-500 h-full w-1/3 animation-delay-200"></div>
      </div>
    </div>
  );
};

export default UnderConstruction;
