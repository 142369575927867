import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js"

import javascript from "../assets/icons/javascript/javascript-plain.svg"
import python from "../assets/skills/python.svg"
import reactIcon from "../assets/skills/react.svg"
import tailwind from "../assets/icons/tailwindcss/tailwindcss-plain.svg"
import mysql from "../assets/icons/mysql/mysql-original.svg"
import apache from "../assets/icons/apache/apache-original.svg"
import nginx from "../assets/icons/nginx/nginx-original.svg"
import git from "../assets/icons/git/git-plain.svg"
import express from "../assets/skills/express.svg"
import gitlab from "../assets/skills/gitlab.svg"
import PHP from "../assets/icons/php/php-plain.svg"

import hr from "../assets/curve-hr.svg"

export default function Skills() {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
      };

    return (
        <div id="skills" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Skills</h1>
            <p className="font-light text-gray-400">Here are some of my skills</p>

            <div className="mt-4">
                <Slider {...settings}>
                <SkillCard name="python" experience="3 years" img={python} />
                <SkillCard name="javascript" experience="3 years" img={javascript} />
                <SkillCard name="react" experience="2 years" img={reactIcon} />
                <SkillCard name="tailwind" experience="1 year" img={tailwind} />
                <SkillCard name="Apache" experience="2,5 years" img={apache} />
                <SkillCard name="Nginx" experience="1 year" img={nginx} />
                <SkillCard name="Git" experience="3 years" img={git} />
                <SkillCard name="express" experience="1 year" img={express} />
                <SkillCard name="gitlab" experience="3 years" img={gitlab} />
                <SkillCard name="MySQL" experience="3 years" img={mysql} />
                <SkillCard name="PHP" experience="2 years" img={PHP} />
                </Slider>
            </div>
            <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
        </div>
    )
}