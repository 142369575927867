import Avatar from "../assets/images/avatar.png";
import hr from "../assets/curve-hr.svg";

export default function AboutMe(props) {
  return (
    <>
      <style>
        {`
    .hover-container:hover .absolute {
      opacity: 80%;
      background-color: rgba(0, 0, 0, 0.8);
    }
  `}
      </style>
      <div id="about" className="mt-4 text-white">
        <h1 className="text-2xl font-bold">About</h1>
        <p className="font-light text-gray-400">
          Here is some information about me
        </p>
        <div
          className="flex flex-col md:flex-row items-center mt-8 p-6 border-2 border-gray-800 bg-dark-200 rounded-md"
          data-aos="fade-left"
        >
          <div className="w-40 h-40 md:w-64 md:h-64 rounded-full overflow-hidden my-2 hover-container relative">
            <img
              src={Avatar}
              alt="Your Name"
              className="object-cover w-full h-full"
            />
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 hover-content"
              style={{
                transition: "opacity 300ms ease-in-out",
              }}
            >
              <h3 className="text-white text-xl font-bold">Lukas Metzler</h3>
            </div>
            <div className="border-4 border-transparent border-t-4 border-gradient h-full w-full rounded-full animate-spin"></div>
          </div>
          <div className="ml-4 mt-4 md:mt-0 md:ml-8">
            <ul className="list-disc list-inside text-gray-400 justify-items-start">
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong className="text-lg md:text-xl font-bold text-gray-300">
                  Age:
                </strong>
                <strong className="font-light text-gray-400"> 23</strong>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong className="text-lg md:text-xl font-bold text-gray-300">
                  Location:
                </strong>{" "}
                <strong className="font-light text-gray-400">Austria</strong>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong className="text-lg md:text-xl font-bold text-gray-300">
                  Profession:
                </strong>{" "}
                <strong className="font-light text-gray-400">
                  Software and Web Developer
                </strong>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong className="text-lg md:text-xl font-bold text-gray-300">
                  Education:
                </strong>{" "}
                <strong className="font-light text-gray-400">
                  Electrical Engineer with Diploma, Currently Studying Computer
                  Science
                </strong>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong className="text-lg md:text-xl font-bold text-gray-300">
                  Languages:
                </strong>{" "}
                <strong className="font-light text-gray-400">
                  German (native), English (fluent in speaking and writing)
                </strong>
              </li>
            </ul>
          </div>
        </div>
        <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
      </div>
    </>
  );
}
